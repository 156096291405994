<script>
	export default {
		name: 'BaseContentBox',
		props: {
			// 类型，map-地图框、menu-菜单框、default-默认框
			type: {
				type: String,
				default: 'default'
			},
			// 标题宽度
			width: {
				type: Number,
				default: 148
			},
			// 标题名称
			title: {
				type: String,
				default: '项目BIM'
			}
		}
	}
</script>

<template>
	<div class="content-box">
		<div class="box-top map" v-if="type == 'map'">
			<div class="polyline">
				<div
					class="polyline-left"
					:style="`width: calc((100% - ${width + 6}px) / 2);`"
				></div>
				<div
					class="polyline-left-decoration"
					:style="`left: calc(50% - ${(width + 6) / 2}px);`"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						version="1.1"
						viewBox="0 0 22 22"
					>
						<defs>
							<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
								<stop offset="0%" style="stop-color: #409FFF;" />
								<stop offset="100%" style="stop-color: #4BA4FF;" />
							</linearGradient>
						</defs>
						<polyline
							points="0,0 22,22"
							stroke="url(#grad1)"
							fill="none"
							stroke-width="1"
						/>
					</svg>
				</div>
				<div
					class="polyline-center"
					:style="
						`width: ${width - 38}px; margin-left: -${(width - 38) / 2}px;`
					"
				></div>
				<div
					class="polyline-right-decoration"
					:style="`right: calc(50% - ${(width + 6) / 2}px);`"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						version="1.1"
						viewBox="0 0 22 22"
					>
						<defs>
							<linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
								<stop offset="0%" style="stop-color: #409FFF;" />
								<stop offset="100%" style="stop-color: #4BA4FF;" />
							</linearGradient>
						</defs>
						<polyline
							points="0,22 22,0"
							stroke="url(#grad2)"
							fill="none"
							stroke-width="1"
						/>
					</svg>
				</div>
				<div
					class="polyline-right"
					:style="`width: calc((100% - ${width + 6}px) / 2);`"
				></div>
			</div>
			<div class="left" :style="`width: calc((100% - ${width - 28}px) / 2);`">
				<div class="first"></div>
				<div class="second"></div>
				<div class="third"></div>
				<div class="fourth"></div>
			</div>
			<div class="right" :style="`width: calc((100% - ${width - 28}px) / 2);`">
				<div class="first"></div>
				<div class="second"></div>
				<div class="third"></div>
				<div class="fourth"></div>
			</div>
			<div
				class="center"
				:style="`width: ${width - 24}px; margin-left: -${(width - 24) / 2}px;`"
			>
				<div class="title">
					{{ title }}
				</div>
			</div>
		</div>
		<div class="box-top default" v-else-if="type == 'default'">
			<!-- 标题梯形 -->
			<div class="trapezoid-title" :style="`width: ${width - 20}px;`"></div>
			<div
				class="trapezoid-decoration-right"
				:style="`width: calc(100% - ${width - 16}px);`"
			></div>
			<div
				class="trapezoid-decoration-bottom"
				:style="`width: ${width + 10}px;`"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					version="1.1"
					:viewBox="`0 0 ${width + 10} 30`"
				>
					<defs>
						<linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
							<stop offset="0%" style="stop-color: #0E5E6E; stop-opacity: 0;" />
							<stop
								offset="100%"
								style="stop-color: #2E84DB; stop-opacity: 50%;"
							/>
						</linearGradient>
					</defs>
					<polyline
						:points="
							`0,22 ${width - 20},22 ${width + 2},0 ${width + 10},0 ${width -
								16},30 0,30`
						"
						fill="url(#grad2)"
					/>
				</svg>
			</div>
			<!-- 梯形折线 -->
			<div class="polyline">
				<div class="polyline-left" :style="`width: ${width - 20}px;`"></div>
				<div class="polyline-center" :style="`left: ${width - 20}px;`">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						version="1.1"
						viewBox="0 0 22 22"
					>
						<defs>
							<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
								<stop offset="0%" style="stop-color: #409FFF;" />
								<stop offset="100%" style="stop-color: #4BA4FF;" />
							</linearGradient>
						</defs>
						<polyline
							points="0,22 22,0"
							stroke="url(#grad1)"
							fill="none"
							stroke-width="1"
						/>
					</svg>
				</div>
				<div
					class="polyline-right"
					:style="`width: calc(100% - ${width + 2}px); left: ${width + 2}px;`"
				></div>
			</div>
			<!-- 三角形 -->
			<div class="triangle">
				<div class="left">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						version="1.1"
						viewBox="0 0 10 14"
					>
						<defs>
							<filter id="shadow1" x="0" y="0" width="200%" height="200%">
								<feOffset result="offOut" in="SourceGraphic" dx="2" dy="0" />
								<feGaussianBlur
									result="blurOut"
									in="offOut"
									stdDeviation="10"
								/>
								<feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
							</filter>
						</defs>
						<polygon
							points="0,0 0,14 10,7"
							fill="rgba(46, 132, 219, 0.5)"
							filter="url(#shadow1)"
						/>
					</svg>
				</div>
				<div class="right">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						version="1.1"
						viewBox="0 0 6 10"
					>
						<defs>
							<filter id="shadow2" x="0" y="0" width="200%" height="200%">
								<feOffset result="offOut" in="SourceGraphic" dx="2" dy="0" />
								<feGaussianBlur
									result="blurOut"
									in="offOut"
									stdDeviation="10"
								/>
								<feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
							</filter>
						</defs>
						<polygon
							points="0,0 0,10 6,5"
							fill="#0095ff"
							filter="url(#shadow2)"
						/>
					</svg>
				</div>
			</div>
			<!-- 修饰盒子 -->
			<div class="decoration-right" :style="` left: ${width - 2}px;`">
				<div class="first"></div>
				<div class="second"></div>
				<div class="third"></div>
				<div class="fourth"></div>
			</div>
			<!-- 标题 -->
			<div class="title" :style="`width: ${width - 20}px;`">
				{{ title }}
			</div>
		</div>
		<div class="box-bottom">
			<slot />
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.content-box {
		width: 100%;
		height: 100%;
		background: linear-gradient(
			180deg,
			rgba(10, 51, 71, 0.09),
			rgba(46, 132, 219, 0.2)
		);
		position: relative;
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 2px;
			background: linear-gradient(
				90deg,
				transparent 5%,
				#42a0ff 50%,
				transparent 95%
			);
			border-radius: 50% / 2px;
		}
		.box-top {
			height: 22px;
			position: relative;
		}
		.box-bottom {
			height: calc(100% - 22px);
		}
	}
	.default {
		.polyline {
			width: 100%;
			height: 1px;
			.polyline-left {
				display: inline-block;
				height: 1px;
				background: linear-gradient(
					90deg,
					rgba(66, 160, 255, 0) 0%,
					#409fff 100%
				);
				position: absolute;
				top: 21px;
				left: 0;
			}
			.polyline-right {
				display: inline-block;
				height: 1px;
				background: linear-gradient(
					90deg,
					#4ba4ff 0%,
					rgba(46, 132, 219, 0) 100%
				);
				position: absolute;
				top: 0;
				right: 0;
			}
			.polyline-center {
				width: 22px;
				height: 22px;
				display: inline-block;
				position: absolute;
				top: 0;
			}
		}
		.trapezoid-title {
			height: 18px;
			position: absolute;
			top: 1px;
			left: 0;
			&:before {
				content: '';
				display: inline-block;
				width: 100%;
				height: 100%;
				background: linear-gradient(
					90deg,
					rgba(0, 149, 255, 0) 0%,
					#00b1ff 100%
				);
				border: 1px solid;
				border-left: none;
				border-image: linear-gradient(90deg, rgba(0, 177, 255, 0) 62%, #ffffff)
					1 1;
				box-shadow: 13px 0 9px 0 #009be6;
				transform: perspective(18px) rotateX(-8deg);
				transform-origin: bottom left;
			}
		}
		.trapezoid-decoration-right {
			height: 18px;
			position: absolute;
			top: 0;
			right: 0;
			background: linear-gradient(
				90deg,
				rgba(0, 149, 255, 0.7) 0%,
				rgba(14, 94, 110, 0) 100%
			);
			transform: perspective(18px) rotateX(0.5deg);
			transform-origin: bottom right;
		}
		.trapezoid-decoration-bottom {
			height: 30px;
			position: absolute;
			top: 0;
			left: 0;
		}
		.decoration-right {
			height: 8px;
			display: inline-flex;
			align-items: flex-end;
			position: absolute;
			top: 1px;
			.first {
				display: inline-block;
				width: 78px;
				height: 8px;
				background: linear-gradient(
					90deg,
					rgba(0, 149, 255, 0) 0%,
					#00b1ff 100%
				);
				transform: skew(-45deg);
				border: 1px solid;
				border-left: none;
				border-image: linear-gradient(90deg, rgba(0, 177, 255, 0) 62%, #ffffff)
					1 1;
			}
			.second {
				display: inline-block;
				width: 12px;
				height: 4px;
				background: #dacf83;
				transform: skew(-45deg);
				margin-left: 6px;
			}
			.third {
				display: inline-block;
				width: 12px;
				height: 4px;
				background: rgba(218, 207, 131, 0.6);
				transform: skew(-45deg);
				margin-left: 8px;
			}
			.fourth {
				display: inline-block;
				width: 12px;
				height: 4px;
				background: rgba(218, 207, 131, 0.4);
				transform: skew(-45deg);
				margin-left: 8px;
			}
		}
		.triangle {
			.left {
				width: 10px;
				height: 14px;
				position: absolute;
				top: 0;
				left: 4px;
			}
			.right {
				width: 6px;
				height: 10px;
				position: absolute;
				top: -2px;
				left: 10px;
			}
		}
		.title {
			height: 16px;
			line-height: 16px;
			position: absolute;
			top: 0;
			left: 0;
			font-size: 15px;
			font-weight: bold;
			color: #ffffff;
			letter-spacing: 2px;
			margin-left: 24px;
		}
	}
	.map {
		.polyline {
			width: 100%;
			height: 1px;
			.polyline-left {
				display: inline-block;
				height: 1px;
				background: linear-gradient(
					90deg,
					rgba(46, 132, 219, 0) 0%,
					#4ba4ff 100%
				);
				position: absolute;
				top: 0;
				left: 0;
			}
			.polyline-left-decoration {
				width: 22px;
				height: 22px;
				display: inline-block;
				position: absolute;
				top: 0;
			}
			.polyline-center {
				display: inline-block;
				height: 1px;
				background: #4ba4ff;
				position: absolute;
				bottom: 0;
				left: 50%;
			}
			.polyline-right-decoration {
				width: 22px;
				height: 22px;
				display: inline-block;
				position: absolute;
				top: 0;
			}
			.polyline-right {
				display: inline-block;
				height: 1px;
				background: linear-gradient(
					90deg,
					#4ba4ff 0%,
					rgba(46, 132, 219, 0) 100%
				);
				position: absolute;
				top: 0;
				right: 0;
			}
		}
		.left {
			height: 8px;
			display: inline-flex;
			align-items: flex-end;
			justify-content: flex-end;
			position: absolute;
			top: 0;
			left: 0;
			&:before {
				content: '';
				display: inline-block;
				width: 100%;
				height: 18px;
				position: absolute;
				top: 0;
				left: 0;
				background: linear-gradient(
					-90deg,
					rgba(0, 149, 255, 0.7) 0%,
					rgba(14, 94, 110, 0) 100%
				);
				transform: perspective(22px) rotateX(4deg);
				transform-origin: bottom left;
			}
			.first {
				display: inline-block;
				width: 12px;
				height: 4px;
				background: rgba(218, 207, 131, 0.4);
				transform: skew(45deg);
				margin-right: 8px;
			}
			.second {
				display: inline-block;
				width: 12px;
				height: 4px;
				background: rgba(218, 207, 131, 0.6);
				transform: skew(45deg);
				margin-right: 8px;
			}
			.third {
				display: inline-block;
				width: 12px;
				height: 4px;
				background: #dacf83;
				transform: skew(45deg);
				margin-right: 6px;
			}
			.fourth {
				display: inline-block;
				width: 78px;
				height: 8px;
				background: linear-gradient(
					-90deg,
					rgba(0, 149, 255, 0) 0%,
					#00b1ff 100%
				);
				margin-right: 12px;
				transform: skew(45deg);
				border: 1px solid;
				border-right: none;
				border-image: linear-gradient(-90deg, rgba(0, 177, 255, 0) 62%, #ffffff)
					1 1;
			}
		}
		.right {
			height: 8px;
			display: inline-flex;
			align-items: flex-end;
			position: absolute;
			top: 0;
			right: 0;
			&:before {
				content: '';
				display: inline-block;
				width: 100%;
				height: 18px;
				position: absolute;
				top: 0;
				left: 0;
				background: linear-gradient(
					90deg,
					rgba(0, 149, 255, 0.7) 0%,
					rgba(14, 94, 110, 0) 100%
				);
				transform: perspective(22px) rotateX(4deg);
				transform-origin: bottom right;
			}
			.first {
				display: inline-block;
				width: 78px;
				height: 8px;
				margin-left: 12px;
				background: linear-gradient(
					90deg,
					rgba(0, 149, 255, 0) 0%,
					#00b1ff 100%
				);
				transform: skew(-45deg);
				border: 1px solid;
				border-left: none;
				border-image: linear-gradient(90deg, rgba(0, 177, 255, 0) 62%, #ffffff)
					1 1;
			}
			.second {
				display: inline-block;
				width: 12px;
				height: 4px;
				background: #dacf83;
				transform: skew(-45deg);
				margin-left: 6px;
			}
			.third {
				display: inline-block;
				width: 12px;
				height: 4px;
				background: rgba(218, 207, 131, 0.6);
				transform: skew(-45deg);
				margin-left: 8px;
			}
			.fourth {
				display: inline-block;
				width: 12px;
				height: 4px;
				background: rgba(218, 207, 131, 0.4);
				transform: skew(-45deg);
				margin-left: 8px;
			}
		}
		.center {
			height: 22px;
			position: absolute;
			top: 2px;
			left: 50%;
			&:before {
				content: '';
				display: inline-block;
				width: 100%;
				height: 18px;
				position: absolute;
				background: linear-gradient(
					90deg,
					rgba(0, 149, 255, 0) 0%,
					rgba(0, 149, 255, 0.8) 50%,
					rgba(0, 149, 255, 0) 100%
				);
				transform: perspective(22px) rotateX(-20deg);
			}
			.title {
				position: absolute;
				top: -2px;
				width: 100%;
				line-height: 15px;
				font-size: 18px;
				font-weight: bold;
				color: #ffffff;
				letter-spacing: 2px;
				text-align: center;
				padding: 0 12px;
			}
		}
	}
</style>
