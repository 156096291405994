<script>
	import moment from 'moment'
	import FullScreenUtil from '@/utils/FullScreenUtil'
	export default {
		name: 'TheHeading',
		data() {
			return {
				appWeek: '',
				appDay: '',
				appTime: '',
				datetimer: null,
				temperature: '25',
				weather: '晴天',
				weathertimer: null,
				isFullScreen: false,
				objScreen: null,
				treeVisible: false,
				expandedKeys: [],
				organStructure: [],
				currentCity: ''
			}
		},
		mounted() {
			moment.locale('zh-cn')
			this.appWeek = moment().format('dddd')
			this.appDay = moment().format('YYYY/MM/DD')
			this.appTime = moment().format('HH:mm')
			this.datetimer = window.setInterval(() => {
				this.appWeek = moment().format('dddd')
				this.appDay = moment().format('YYYY/MM/DD')
				this.appTime = moment().format('HH:mm')
			}, 1000)

			this.objScreen = new FullScreenUtil(this.screenChange)
		},
		destroyed() {
			window.clearInterval(this.datetimer)
			window.clearInterval(this.weathertimer)
			this.objScreen.destroy()
		},
		methods: {
			toHome() {
				this.$router.push({
					name: 'Index'
				})
			},
			screenChange(isFullScreen) {
				this.isFullScreen = isFullScreen
			},
			screenHandle() {
				this.objScreen && this.objScreen.toggleFullScreen()
			}
		}
	}
</script>

<template>
	<header class="top-header">
		<div class="header-left">
			<!-- <img class="header-logo" src="../../assets/img/header_logo.png" alt="" /> -->
		</div>
		<div class="header-center">
			<div class="title">基筑</div>
			<div class="sub-title">SMART SITE</div>
		</div>
		<div class="header-right">
			<time class="time-item">{{ appTime }}</time>
			<div class="right-item">
				<div class="item-detail">
					<time class="week">{{ appWeek }}</time>
					<time class="date">{{ appDay }}</time>
				</div>
			</div>
			<div class="right-item">
				<i class="ali-icon ali-icon-weather"></i>
				<div class="item-detail">
					<time class="weather">{{ weather }}</time>
					<time class="temperature">{{ temperature }}&deg;C</time>
				</div>
			</div>
			<a-popover placement="bottom" content="首页">
				<div class="right-toolbar" @click="toHome">
					<i class="ali-icon ali-icon-nav-home"></i>
				</div>
			</a-popover>
			<a-popover
				placement="bottom"
				:content="isFullScreen ? '退出全屏' : '全屏'"
			>
				<div class="right-toolbar" @click="screenHandle">
					<i
						:class="
							`ali-icon ali-icon-action-${
								isFullScreen ? 'exitscreen' : 'fullscreen'
							}`
						"
					></i>
				</div>
			</a-popover>
			<div class="user-avatar">
				<div class="avatar-container">
					<!-- <img class="avatar-image" src="../../assets/img/logo.png" alt="" /> -->
				</div>
			</div>
		</div>
	</header>
</template>

<style lang="scss" scoped>
	.top-header {
		width: 100%;
		height: 100px;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		background: url('../../assets/img/header_decoration.png');
		background-size: 100% auto;
		background-position: center bottom;
		background-repeat: no-repeat;
		.header-left {
			height: 84px;
			flex: 1;
			display: flex;
			align-items: center;
			.header-logo {
				width: 149px;
				height: 61px;
				margin-right: 20px;
			}
		}
		.header-center {
			height: 90px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-family: fontquan;
			.title {
				font-size: 40px;
				line-height: 40px;
				color: #ffffff;
				letter-spacing: 18px;
				text-indent: 20px;
				margin-bottom: 8px;
			}
			.sub-title {
				font-size: 14px;
				line-height: 14px;
				color: #8296b3;
				letter-spacing: 2px;
			}
		}
		.header-right {
			height: 84px;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			color: #ffffff;
			.time-item {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 72px;
				font-family: digital;
				font-size: 30px;
				position: relative;
				margin-right: 10px;
			}
			.right-item {
				display: inline-flex;
				align-items: center;
				padding: 0 20px;
				position: relative;
				&:before {
					content: '';
					width: 1px;
					height: 18px;
					background: rgba(148, 148, 148, 0.5);
					position: absolute;
					left: 0;
					top: 50%;
					margin-top: -9px;
				}
				.ali-icon {
					font-size: 36px;
					margin-right: 8px;
				}
				.item-detail {
					display: inline-flex;
					flex-direction: column;
					justify-content: center;
					.week {
						font-size: 16px;
						line-height: 20px;
						font-weight: bold;
					}
					.date {
						font-family: digital;
						font-size: 16px;
						line-height: 16px;
					}
					.weather {
						font-size: 14px;
						line-height: 20px;
						letter-spacing: 2px;
					}
					.temperature {
						display: inline-flex;
						align-items: center;
						font-family: digital;
						font-size: 14px;
						height: 18px;
					}
				}
			}
			.right-toolbar {
				width: 28px;
				height: 28px;
				margin-left: 16px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background: #0095ff;
				color: #0a0c27;
				border-radius: 4px;
				&:hover {
					cursor: pointer;
					background: #29adff;
					color: #ffffff;
				}
				.ali-icon {
					font-size: 16px;
				}
			}
			.user-avatar {
				width: 40px;
				height: 40px;
				margin-left: 16px;
				padding: 3px;
				border-radius: 50%;
				border: 1px solid #979797;
				cursor: pointer;
				.avatar-container {
					width: 32px;
					height: 32px;
					padding: 6px;
					background: #ffffff;
					border-radius: 50%;
					display: flex;
					.avatar-image {
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
</style>
