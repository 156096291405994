<script>
	import TheHeading from './TheHeading'
	import BaseContentBox from './BaseContentBox'
	import * as THREE from 'three'
	import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
	import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
	import Vue from 'vue'
	import LabelBuilding from './LabelBuilding'

	let container, containerWidth, containerHeight
	let raycaster = new THREE.Raycaster()
	let mouseVector = new THREE.Vector3()
	let activePoint
	let tags = []
	export default {
		name: 'BIM',
		components: {
			TheHeading,
			BaseContentBox
		},
		data() {
			return {
				step: 1,
				scene: null,
				camera: null,
				hemisphereLight: null,
				renderer: null,
				clock: null,
				orbitControls: null,
				modelLoaded: 0,
				timer: null,
				timeCount: 0,
				isCollapsed: true,
				floorShow: false,
				floorCount: 18,
				floorSelect: 0,
				dataPanelShow: false,
				dataPanelType: 0,
				dataPanelTab: 0,
				tagObject: null,
				mesh: [],
				labels: [
					{
						type: 'building',
						position: {
							x: -10,
							y: 10,
							z: 90
						},
						title: '1栋',
						selected: false
					},
					{
						type: 'building',
						position: {
							x: 10,
							y: 25,
							z: 40
						},
						title: '2栋',
						selected: false
					},
					{
						type: 'building',
						position: {
							x: -20,
							y: -50,
							z: 60
						},
						title: '3栋',
						selected: false
					},
					{
						type: 'door',
						position: {
							x: 60,
							y: -50,
							z: 5
						},
						title: '门禁',
						selected: false
					},
					{
						type: 'environment',
						position: {
							x: 55,
							y: -95,
							z: 5
						},
						title: '环境监测',
						selected: false
					},
					{
						type: 'tower',
						position: {
							x: -20,
							y: 50,
							z: 50
						},
						title: '塔吊',
						selected: false
					},
					{
						type: 'monitor',
						position: {
							x: 10,
							y: -50,
							z: 10
						},
						title: '摄像头',
						selected: false
					}
				]
			}
		},
		mounted() {
			container = document.getElementById('container')
			containerWidth = container.clientWidth
			containerHeight = container.clientHeight

			// 定义摄像机
			this.camera = new THREE.PerspectiveCamera(
				45,
				containerWidth / containerHeight,
				0.001,
				10000
			)
			this.camera.position.set(
				-4.90563440219497,
				-259.65461581681495,
				141.88912950593746
			)

			// 定义场景
			this.scene = new THREE.Scene()
			this.scene.rotation.z = 55.01
			this.scene.translateZ(-30)

			// 定义辅助线
			// let axesHelper = new THREE.AxesHelper(1000)
			// this.scene.add(axesHelper)

			// 定义光线
			let ambientLight = new THREE.AmbientLight(0xffffff)
			this.scene.add(ambientLight)

			// 加载模型
			let loader = new GLTFLoader()
			loader.load(
				'/work-site/model.gltf',
				(gltf) => {
					this.mesh.push(gltf.scene.children[0])
					this.scene.add(gltf.scene)
					// 创建标签
					this.tagObject = new THREE.Object3D()
					this.scene.add(this.tagObject)
					this.renderLabel()
					this.modelLoaded = 100
					this.timeCount = 10
				},
				(xhr) => {
					if (xhr.lengthComputable) {
						let percentComplete = (xhr.loaded / xhr.total) * 100
						this.modelLoaded = Math.round(percentComplete, 2)
					}
				}
			)

			// 定义渲染器
			this.renderer = new THREE.WebGLRenderer({
				alpha: true,
				antialias: true
			})
			this.renderer.setSize(containerWidth, containerHeight)
			this.renderer.setPixelRatio(window.devicePixelRatio)

			//设置轨迹球控制器
			this.orbitControls = new OrbitControls(this.camera, container)
			this.orbitControls.minDistance = 1
			this.orbitControls.maxDistance = 10000
			this.orbitControls.enableKeys = false
			this.orbitControls.minPolarAngle = 0
			this.orbitControls.maxPolarAngle = 180
			this.orbitControls.minAzimuthAngle = 0
			this.orbitControls.maxAzimuthAngle = 180
			this.orbitControls.saveState()
			this.clock = new THREE.Clock()

			// 监听鼠标事件
			document.addEventListener('mousewheel', () => {
				this.clearTimer()
				this.setTimer()
			})
			container.addEventListener('mousedown', () => {
				this.clearTimer()
			})
			container.addEventListener('mouseup', () => {
				this.setTimer()
			})

			// 将渲染结果挂载到DOM元素上
			this.$refs.container.append(this.renderer.domElement)

			// 渲染场景
			this.render()

			container.addEventListener('click', this.onPointerClick)
		},
		methods: {
			render() {
				this.orbitControls.update(this.clock.getDelta())
				tags.forEach(function(tagMesh) {
					tagMesh.updateTag()
				})
				if (this.modelLoaded == 100 && this.timeCount >= 10) {
					// this.scene.rotation.z += 0.0005
				}
				requestAnimationFrame(this.render)
				this.renderer.render(this.scene, this.camera)
			},
			setTimer() {
				this.timeCount = 0
				this.timer = setInterval(() => {
					if (this.timeCount < 10) {
						this.timeCount += 1
					}
				}, 1000)
			},
			clearTimer() {
				clearInterval(this.timer)
				this.timer = null
				this.timeCount = 0
			},
			rotateLeft() {
				this.clearTimer()
				this.setTimer()
				this.scene.rotation.z -= 0.05
			},
			reset() {
				this.clearTimer()
				this.setTimer()
				this.camera.position.set(
					-4.90563440219497,
					-259.65461581681495,
					141.88912950593746
				)
				this.scene.rotation.z = 55.01
				this.scene.position.z = 0
				this.scene.translateZ(-30)
				this.orbitControls.reset()
			},
			rotateRight() {
				this.clearTimer()
				this.setTimer()
				this.scene.rotation.z += 0.05
			},
			selectFloor(floor) {
				this.floorSelect = floor
			},
			toMonitor() {
				this.$api.queryCameraAccountInfo().then((res) => {
					if (res.code === 200) {
						this.accessToken = res.data.token
						window.open(
							'https://open.ys7.com/ezopen/h5/iframe_se?url=ezopen://BDZYWK@open.ys7.com/F43457002/10.live' +
								'&accessToken=' +
								res.data.token,
							'_blank'
						)
					}
				})
			},
			toScreenPosition(obj, camera) {
				let vector = new THREE.Vector3()
				obj.updateMatrixWorld()
				vector.setFromMatrixPosition(obj.matrixWorld)
				vector.project(camera)
				vector.x = 0.5 * vector.x * containerWidth + 0.5 * containerWidth
				vector.y = -(0.5 * vector.y * containerHeight) + 0.5 * containerHeight
				return {
					x: vector.x,
					y: vector.y
				}
			},
			isOffScreen(obj, camera) {
				let frustum = new THREE.Frustum()
				let cameraViewProjectionMatrix = new THREE.Matrix4()
				cameraViewProjectionMatrix.multiplyMatrices(
					camera.projectionMatrix,
					camera.matrixWorldInverse
				)
				frustum.setFromProjectionMatrix(cameraViewProjectionMatrix)
				return !frustum.intersectsObject(obj)
			},
			onPointerClick(event) {
				// 获取当前鼠标三维坐标
				mouseVector.x = 2 * (event.clientX / containerWidth) - 1
				mouseVector.y = -2 * (event.clientY / containerHeight) + 1
				raycaster.setFromCamera(mouseVector.clone(), this.camera)
				let intersects = raycaster.intersectObjects(this.mesh)
				if (intersects.length > 0) {
					activePoint = intersects[0].point
				}
				console.log(activePoint)
			},
			renderLabel() {
				// let labels = [
				// 	{
				// 		type: 'building',
				// 		position: {
				// 			x: -10,
				// 			y: 10,
				// 			z: 90
				// 		},
				// 		title: '1栋',
				// 		selected: false
				// 	},
				// 	{
				// 		type: 'building',
				// 		position: {
				// 			x: 10,
				// 			y: 25,
				// 			z: 40
				// 		},
				// 		title: '2栋',
				// 		selected: false
				// 	},
				// 	{
				// 		type: 'building',
				// 		position: {
				// 			x: -20,
				// 			y: -50,
				// 			z: 60
				// 		},
				// 		title: '3栋',
				// 		selected: false
				// 	},
				// 	{
				// 		type: 'door',
				// 		position: {
				// 			x: 60,
				// 			y: -50,
				// 			z: 5
				// 		},
				// 		title: '门禁',
				// 		selected: false
				// 	},
				// 	{
				// 		type: 'environment',
				// 		position: {
				// 			x: 55,
				// 			y: -95,
				// 			z: 5
				// 		},
				// 		title: '环境监测',
				// 		selected: false
				// 	},
				// 	{
				// 		type: 'tower',
				// 		position: {
				// 			x: -20,
				// 			y: 50,
				// 			z: 50
				// 		},
				// 		title: '塔吊',
				// 		selected: false
				// 	},
				// 	{
				// 		type: 'monitor',
				// 		position: {
				// 			x: 10,
				// 			y: -50,
				// 			z: 10
				// 		},
				// 		title: '摄像头',
				// 		selected: false
				// 	}
				// ]
				this.labels.forEach((item, index) => {
					let tagMesh = new THREE.Mesh(
						new THREE.SphereGeometry(1),
						new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 })
					)
					tagMesh.position.set(
						item.position.x,
						item.position.y,
						item.position.z
					)
					this.tagObject.add(tagMesh)
					let dom = new Vue({
						el: document.createElement('div'),
						render: (h) => {
							return h(LabelBuilding, {
								props: {
									type: item.type,
									title: item.title,
									selected: item.selected
								},
								on: {
									'on-click': () => {
										this.labels.forEach((litem) => {
											litem.selected = false
										})
										item.selected = true
									},
									'on-dblclick': () => {
										if (item.type == 'building') {
											this.floorShow = true
											if (index == 0) {
												this.floorCount = 18
											}
											if (index == 1) {
												this.floorCount = 8
											}
											if (index == 2) {
												this.floorCount = 9
											}
											this.dataPanelShow = true
											this.dataPanelType = 1
											this.dataPanelTab = 0
											this.isCollapsed = false
										} else {
											this.dataPanelShow = true
											this.dataPanelType = 0
											this.dataPanelTab = 0
											this.isCollapsed = false
										}
									}
								}
							})
						}
					})
					dom.$el.style.position = 'absolute'
					tagMesh.updateTag = () => {
						if (this.isOffScreen(tagMesh, this.camera)) {
							dom.$el.style.display = 'none'
						} else {
							dom.$el.style.display = 'flex'
							var position = this.toScreenPosition(tagMesh, this.camera)
							dom.$el.style.left = parseInt(position.x) + 'px'
							dom.$el.style.top = parseInt(position.y) + 'px'
						}
					}
					tagMesh.updateTag()
					container.appendChild(dom.$el)
					tags.push(tagMesh)
				})
			}
		}
	}
</script>

<template>
	<div class="bim">
		<TheHeading />
		<div class="bim-bottom">
			<BaseContentBox>
				<div class="bim-content">
					<div class="bim-container" id="container" ref="container"></div>
					<!-- 操作按钮 -->
					<div class="bim-button">
						<i class="ali-icon ali-icon-rotate3d" @click="rotateLeft"></i>
						<i class="ali-icon ali-icon-reset" @click="reset"></i>
						<i class="ali-icon ali-icon-rotate3d" @click="rotateRight"></i>
					</div>
					<!-- 模型标签 -->
					<!-- 折叠面板 -->
					<div :class="{ 'collapse-panel': true, 'collapsed': isCollapsed }">
						<img src="../../assets/img/collapse_panel.png" alt="" />
					</div>
					<div
						:class="{ 'collapse-button': true, 'collapsed': isCollapsed }"
						@click="isCollapsed = !isCollapsed"
					></div>
					<!-- 楼层刻度尺 -->
					<div class="floor-ruler" v-if="floorShow">
						<a
							:class="{ 'floor-item': true, 'active': floorSelect == 0 }"
							href="javascript:void(0)"
							@click="selectFloor(0)"
						>
							全楼
						</a>
						<a
							:class="{
								'floor-item': true,
								'active': floorSelect == floorCount - item + 1
							}"
							href="javascript:void(0)"
							v-for="item in floorCount"
							:key="floorCount - item + 1"
							@click="selectFloor(floorCount - item + 1)"
						>
							{{ floorCount - item + 1 }}F
						</a>
					</div>
					<!-- 数据面板 -->
					<a-modal
						class="data-panel"
						:title="dataPanelType == 1 ? '项目详情' : '设备详情'"
						:width="608"
						:centered="true"
						:mask="false"
						:maskClosable="false"
						:visible="dataPanelShow"
						:footer="null"
						@cancel="dataPanelShow = false"
					>
						<ul class="panel-tab" v-if="dataPanelType == 1">
							<li
								:class="{ 'tab-item': true, 'active': dataPanelTab == 0 }"
								@click="dataPanelTab = 0"
							>
								综合概况
							</li>
							<li
								:class="{ 'tab-item': true, 'active': dataPanelTab == 1 }"
								@click="dataPanelTab = 1"
							>
								计划进度
							</li>
							<li
								:class="{ 'tab-item': true, 'active': dataPanelTab == 2 }"
								@click="dataPanelTab = 2"
							>
								质量监管
							</li>
							<li
								:class="{ 'tab-item': true, 'active': dataPanelTab == 3 }"
								@click="dataPanelTab = 3"
							>
								安全生产
							</li>
							<li
								:class="{ 'tab-item': true, 'active': dataPanelTab == 4 }"
								@click="dataPanelTab = 4"
							>
								履职评价
							</li>
						</ul>
						<ul class="panel-tab" v-if="dataPanelType != 1">
							<li
								:class="{ 'tab-item': true, 'active': dataPanelTab == 0 }"
								@click="dataPanelTab = 0"
							>
								设备监测
							</li>
							<li
								:class="{ 'tab-item': true, 'active': dataPanelTab == 1 }"
								@click="dataPanelTab = 1"
							>
								预警记录
							</li>
							<li
								:class="{ 'tab-item': true, 'active': dataPanelTab == 2 }"
								@click="dataPanelTab = 2"
							>
								设备台账
							</li>
						</ul>
						<div
							class="tab-content"
							v-if="dataPanelType == 1 && dataPanelTab == 0"
						>
							<img src="../../assets/img/overview.png" alt="" />
						</div>
						<div
							class="tab-content"
							v-if="dataPanelType == 1 && dataPanelTab == 1"
						>
							<img src="../../assets/img/progress.png" alt="" />
						</div>
						<div
							class="tab-content"
							v-if="dataPanelType == 1 && dataPanelTab == 2"
						>
							<img src="../../assets/img/quality.png" alt="" />
						</div>
						<div
							class="tab-content"
							v-if="dataPanelType == 1 && dataPanelTab == 3"
						>
							<img src="../../assets/img/safety.png" alt="" />
						</div>
						<div
							class="tab-content"
							v-if="dataPanelType == 1 && dataPanelTab == 4"
						>
							<img src="../../assets/img/performance.png" alt="" />
						</div>
						<div
							class="tab-content"
							v-if="dataPanelType != 1 && dataPanelTab == 0"
						>
							<img src="../../assets/img/video.png" alt="" @click="toMonitor" />
						</div>
						<div
							class="tab-content"
							v-if="dataPanelType != 1 && dataPanelTab == 1"
						>
							<img src="../../assets/img/alarm.png" alt="" />
						</div>
						<div
							class="tab-content"
							v-if="dataPanelType != 1 && dataPanelTab == 2"
						>
							<img src="../../assets/img/device.png" alt="" />
						</div>
					</a-modal>
					<!-- 加载状态 -->
					<div class="bim-loading" v-if="modelLoaded != 100">
						<div class="loading-tips">{{ modelLoaded }}%</div>
						<svg
							version="1.1"
							id="dc-spinner"
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							width="38"
							height="38"
							viewBox="0 0 38 38"
							preserveAspectRatio="xMinYMin meet"
						>
							<path
								fill="#373a42"
								d="M20,35c-8.271,0-15-6.729-15-15S11.729,5,20,5s15,6.729,15,15S28.271,35,20,35z M20,5.203
					    C11.841,5.203,5.203,11.841,5.203,20c0,8.159,6.638,14.797,14.797,14.797S34.797,28.159,34.797,20
					    C34.797,11.841,28.159,5.203,20,5.203z"
							></path>

							<path
								fill="#373a42"
								d="M20,33.125c-7.237,0-13.125-5.888-13.125-13.125S12.763,6.875,20,6.875S33.125,12.763,33.125,20
					    S27.237,33.125,20,33.125z M20,7.078C12.875,7.078,7.078,12.875,7.078,20c0,7.125,5.797,12.922,12.922,12.922
					    S32.922,27.125,32.922,20C32.922,12.875,27.125,7.078,20,7.078z"
							></path>

							<path
								fill="#2AA198"
								stroke="#2AA198"
								stroke-width="0.6027"
								stroke-miterlimit="10"
								d="M5.203,20
								c0-8.159,6.638-14.797,14.797-14.797V5C11.729,5,5,11.729,5,20s6.729,15,15,15v-0.203C11.841,34.797,5.203,28.159,5.203,20z"
							>
								<animateTransform
									attributeName="transform"
									type="rotate"
									from="0 20 20"
									to="360 20 20"
									calcMode="spline"
									keySplines="0.4, 0, 0.2, 1"
									keyTimes="0;1"
									dur="2s"
									repeatCount="indefinite"
								/>
							</path>

							<path
								fill="#859900"
								stroke="#859900"
								stroke-width="0.2027"
								stroke-miterlimit="10"
								d="M7.078,20
					  c0-7.125,5.797-12.922,12.922-12.922V6.875C12.763,6.875,6.875,12.763,6.875,20S12.763,33.125,20,33.125v-0.203
					  C12.875,32.922,7.078,27.125,7.078,20z"
							>
								<animateTransform
									attributeName="transform"
									type="rotate"
									from="0 20 20"
									to="360 20 20"
									dur="1.8s"
									repeatCount="indefinite"
								/>
							</path>
						</svg>
					</div>
					<!-- 瓦片地图 -->
					<div class="map-tile" v-if="step == 2">
						<img src="../../assets/img/maptile.png" alt="" />
						<div class="map-label" @click="step = 3">
							<label>龙胜项目</label>
							<div class="decoration1"></div>
							<div class="decoration2"></div>
							<div class="decoration3"></div>
						</div>
					</div>
					<!-- 轮廓地图 -->
					<div class="geojson" v-if="step == 1">
						<img src="../../assets/img/geojson.png" alt="" />
						<div class="map-label" @click="step = 2">
							<label>龙胜项目</label>
							<div class="decoration1"></div>
							<div class="decoration2"></div>
							<div class="decoration3"></div>
						</div>
					</div>
				</div>
			</BaseContentBox>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.bim {
		width: 100vw;
		height: 100vh;
		background: #060f20;
		.bim-bottom {
			margin: 8px;
			height: calc(100% - 116px);
			display: flex;
		}
		.bim-content {
			width: 100%;
			height: 100%;
			position: relative;
			.bim-container {
				width: 100%;
				height: 100%;
				position: relative;
			}
		}
	}
	.bim-button {
		position: absolute;
		right: 16px;
		bottom: 16px;
		.ali-icon {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 36px;
			height: 36px;
			opacity: 1;
			background: rgba(1, 148, 252, 0.27);
			border: 1px solid #00b8f1;
			border-radius: 50%;
			font-size: 18px;
			&:hover {
				cursor: pointer;
				background: rgba(1, 148, 252, 0.62);
			}
			&:not(:last-child) {
				margin-right: 16px;
			}
			&:first-child {
				transform: rotateY(180deg);
			}
		}
	}
	.collapse-panel {
		width: 400px;
		transition: width 1s;
		position: absolute;
		left: 0;
		top: 15px;
		bottom: 15px;
		overflow-x: hidden;
		overflow-y: auto;
		background: linear-gradient(
			90deg,
			rgba(10, 51, 71, 0.28),
			rgba(46, 132, 219, 0.2)
		);
		border-radius: 0px 12px 12px 0px;
		&.collapsed {
			width: 0;
			transition: width 1s;
		}
		img {
			margin: 15px 0 0 15px;
		}
	}
	.collapse-button {
		width: 12px;
		height: 64px;
		background: #1e3851;
		position: absolute;
		left: 400px;
		transition: left 1s;
		top: 50%;
		margin-top: -32px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0 8px 8px 0 / 0 12px 12px 0;
		&.collapsed {
			left: 0;
			transition: left 1s;
		}
		&:hover {
			cursor: pointer;
			background: rgba(46, 132, 219, 0.4);
		}
		&:before {
			content: '';
			display: inline-block;
			width: 2px;
			height: 18px;
			background: #ffffff;
		}
	}
	.floor-ruler {
		width: 72px;
		padding: 9px;
		position: absolute;
		right: 15px;
		top: 15px;
		background: #0b283f;
		border: 1px solid #1f4caf;
		border-radius: 6px;
		box-shadow: 0px 1px 10px 0px rgba(49, 140, 255, 0.37) inset;
		.floor-item {
			display: block;
			width: 54px;
			height: 24px;
			text-align: center;
			line-height: 22px;
			font-size: 14px;
			color: #ffffff;
			border: 1px solid transparent;
			&:not(:last-child) {
				margin-bottom: 9px;
			}
			&.active,
			&:hover {
				cursor: pointer;
				font-weight: bold;
				background: #0b283f;
				border: 1px solid #6f9cff;
				border-radius: 12px;
				box-shadow: 0px 1px 10px 0px #318cff inset;
			}
		}
	}
	.data-panel {
		/deep/ {
			.ant-modal-content {
				height: 363px;
				background: url('../../assets/img/data_panel.png');
			}
			.ant-modal-header {
				background: transparent;
				border: none;
				padding: 0;
			}
			.ant-modal-close {
				color: #ffffff;
				top: 24px;
				right: 8px;
			}
			.ant-modal-close-x {
				width: 24px;
				height: 24px;
				line-height: 24px;
			}
			.ant-modal-title {
				font-size: 16px;
				font-family: 'fontquan';
				color: #ffffff;
				letter-spacing: 4px;
				text-shadow: 0px 2px 9px rgba(0, 0, 0, 0.77),
					0px 2px 0px rgba(0, 0, 0, 0.5);
			}
			.ant-modal-body {
				padding: 16px 24px;
			}
		}
	}
	.panel-tab {
		text-align: center;
		.tab-item {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 84px;
			height: 24px;
			background: rgba(1, 148, 252, 0.27);
			background: rgba(1, 148, 252, 0.05);
			border: 1px solid rgba(0, 183, 239, 0.38);
			color: #ffffff;
			font-size: 12px;
			&:not(:last-child) {
				margin-right: 8px;
			}
			&.active,
			&:hover {
				cursor: pointer;
				background: rgba(1, 148, 252, 0.27);
				border: 1px solid transparent;
				border-image: linear-gradient(90deg, #00b8f1, rgba(0, 130, 223, 0)) 1 1;
			}
		}
		.tab-content {
			text-align: center;
		}
	}
	.map-tile {
		width: 100%;
		height: 100%;
		position: absolute;
		background: #060f20;
		top: 0;
		left: 0;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.geojson {
		width: 100%;
		height: 100%;
		position: absolute;
		background: #060f20;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-width: 62%;
			max-height: 62%;
		}
	}
	.map-label {
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 7px;
		border-radius: 4px;
		background: #0f2741;
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
		color: #ffffff;
		font-size: 14px;
		line-height: 14px;
		cursor: pointer;
		&:hover {
			cursor: pointer;
			background: #0d72ba;
			z-index: 10;
		}
		.decoration1 {
			width: 12px;
			height: 12px;
			position: absolute;
			top: 8px;
			left: -16px;
			background: #ffd9de;
			border: 1px solid #d36218;
			border-radius: 50%;
			box-shadow: 0px 0px 10px 0px #ff1616;
		}
		.decoration2 {
			content: '';
			width: 8px;
			height: 8px;
			position: absolute;
			top: 10px;
			left: -14px;
			border: 1px solid #979797;
			border-radius: 50%;
		}
		.decoration3 {
			content: '';
			width: 4px;
			height: 4px;
			position: absolute;
			top: 12px;
			left: -12px;
			background: #d36218;
			border-radius: 50%;
		}
	}
	.bim-loading {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		background: #060f20;
		-webkit-perspective: 600;
		perspective: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		.loading-tips {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #ffffff;
			font-size: 24px;
			line-height: 24px;
			padding: 10px 0 0 10px;
			font-weight: bold;
			font-family: Arial;
		}
	}
	.bim-loading svg {
		width: 240px;
		height: 240px;
	}
	.dc-logo {
		position: fixed;
		right: 10px;
		bottom: 10px;
	}
	.dc-logo:hover svg {
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-animation: arrow-spin 2.5s 0s cubic-bezier(0.165, 0.84, 0.44, 1)
			infinite;
		animation: arrow-spin 2.5s 0s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
	}
	.dc-logo:hover:hover:before {
		content: '\2764';
		padding: 6px;
		font: 10px/1 Monaco, sans-serif;
		font-size: 10px;
		color: #00fffe;
		text-transform: uppercase;
		position: absolute;
		left: -70px;
		top: -30px;
		white-space: nowrap;
		z-index: 20px;
		box-shadow: 0px 0px 4px #222;
		background: rgba(0, 0, 0, 0.4);
	}
	.dc-logo:hover:hover:after {
		content: 'Digital Craft';
		padding: 6px;
		font: 10px/1 Monaco, sans-serif;
		font-size: 10px;
		color: #6e6f71;
		text-transform: uppercase;
		position: absolute;
		right: 0;
		top: -30px;
		white-space: nowrap;
		z-index: 20px;
		box-shadow: 0px 0px 4px #222;
		background: rgba(0, 0, 0, 0.4);
		background-image: none;
	}
	@-webkit-keyframes arrow-spin {
		50% {
			-webkit-transform: rotateY(360deg);
			transform: rotateY(360deg);
		}
	}
	@keyframes arrow-spin {
		50% {
			-webkit-transform: rotateY(360deg);
			transform: rotateY(360deg);
		}
	}
</style>
