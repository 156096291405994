<template>
	<div :class="{ 'model-label': true, 'selected': selected }">
		<div class="title">{{ title }}</div>
		<img
			v-if="type == 'monitor'"
			src="../../assets/img/monitor.png"
			alt=""
			@click="imgClick"
			@dblclick="imgDbClick"
		/>
		<img
			v-else-if="type == 'door'"
			src="../../assets/img/door.png"
			alt=""
			@click="imgClick"
			@dblclick="imgDbClick"
		/>
		<img
			v-else-if="type == 'tower'"
			src="../../assets/img/tower.png"
			alt=""
			@click="imgClick"
			@dblclick="imgDbClick"
		/>
		<img
			v-else-if="type == 'environment'"
			src="../../assets/img/environment.png"
			alt=""
			@click="imgClick"
			@dblclick="imgDbClick"
		/>
		<img
			v-else
			src="../../assets/img/building.png"
			alt=""
			@click="imgClick"
			@dblclick="imgDbClick"
		/>
	</div>
</template>

<script>
	export default {
		name: 'ModelLabel',
		props: {
			type: {
				type: String,
				default: 'building'
			},
			title: {
				type: String,
				default: ''
			},
			selected: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {}
		},
		methods: {
			imgClick() {
				this.$emit('on-click')
			},
			imgDbClick() {
				this.$emit('on-dblclick')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.model-label {
		width: 96px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		&.selected {
			display: inline-flex;
		}
		.title {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 96px;
			height: 32px;
			margin-bottom: 5px;
			background: linear-gradient(
				90deg,
				rgba(59, 138, 255, 0) 0%,
				rgba(59, 138, 255, 0.15) 15%,
				rgba(59, 138, 255, 0.75) 50%,
				rgba(59, 138, 255, 0.15) 85%,
				rgba(59, 138, 255, 0) 100%
			);
			border: 1px solid;
			border-left: none;
			border-right: none;
			border-image: linear-gradient(
					90deg,
					rgba(59, 138, 255, 0),
					rgba(137, 185, 255, 0.75) 50%,
					rgba(59, 138, 255, 0)
				)
				1 1;
			color: #ffffff;
			text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
		}
		img {
			cursor: pointer;
		}
	}
</style>
